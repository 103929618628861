import React, { useContext } from "react";
import { FacadeContext } from "./context/FacadeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Info() {
  const {
    showInfo,
    setShowInfo,
    observationAttributes,
    visualizationMode,
    setVisualizationMode,
  } = useContext(FacadeContext);

  const InfoBox = ({ observationInfo }) => {
    return (
      <div className="facade-attribute-wrapper">
        <div className="facade-attribute-container">
          <div className="facade-attribute-header d-flex justify-content-between align-items-center mb-3">
            {!visualizationMode ? (
              <h4 className="facade-attribute-heading small">Category info</h4>
            ) : (
              <h4 className="facade-attribute-heading small">
                Color reference by category
              </h4>
            )}
            <div
              className="facade--close cursor-pointer"
              onClick={() => setShowInfo(false)}
            >
              ×
            </div>
          </div>
          {!visualizationMode ? (
            <div className="facade-attribute-content">
              {observationInfo.map((o) => {
                return (
                  <div key={o.code} className="mt-2 small">
                    <p className="bold mb-1">
                      {o.code} - {o.description}
                    </p>
                    <p className="pre-line">{o.help_text}</p>
                    <hr />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="facade-attribute-content">
              {observationAttributes.current.facadePrimary.map((c) => {
                return (
                  <div key={c.code} className="mt-2 colors-container">
                    <div
                      className="color-reference"
                      style={{ backgroundColor: c.color }}
                    ></div>
                    <div>
                      <p className="bold mb-1 category-container">{c.code} </p>
                    </div>

                    <div>
                      <p className="mb-1 category-container">
                        ({c.quantity} {c.quantity === 1 ? "observation" : "observations"})
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="mt-2 colors-container">
                <div
                  className="color-reference"
                  style={{ backgroundColor: "#000000" }}
                ></div>
                <div>
                  <p className="mb-1 category-container color-gray">No observations (yet)</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        id="info-btn"
        className="action-btn action-btn-sm"
        onClick={() => setShowInfo(true)}
      >
        <FontAwesomeIcon icon="fa-solid fa-circle-info" />
      </div>

      {showInfo && (
        <InfoBox
          observationInfo={observationAttributes.current.facadePrimary}
        />
      )}
      <div
        id="visualization-btn"
        className="action-btn action-btn-sm"
        onClick={() => setVisualizationMode((prevState) => !prevState)}
      >
        {visualizationMode ? (
          <FontAwesomeIcon icon="fa-solid fa-eye" />
        ) : (
          <FontAwesomeIcon icon="fa-solid fa-eye-slash" />
        )}
      </div>
    </>
  );
}
export default Info;
