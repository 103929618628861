import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import axios from "../../lib/axios-instance";
import { addColorToFacades } from "../../utils/utils";
import { feature, featureCollection } from "@turf/helpers";

mapboxgl.accessToken =
  "pk.eyJ1IjoibmluYWNlY2lsaWUiLCJhIjoiY2thcXNicW4yMDNmMzJzbzVtcnBjZWNzOSJ9.a3-LyH4VeC8vprevn4BVnw";

const ViewData = ({ project }) => {
  const mapContainer = useRef(null);
  const [facadeSetId, setFacadeSetid] = useState();
  const [currentFacadeSet, setCurrentFacadeSet] = useState();
  const [selectedObservations, setSelectedObservations] = useState({
    type: "FeatureCollection",
    features: [],
  });
  // console.log(project)
  useEffect(() => {
    addColorToFacades(project.facade_observation_attribute_definitions);
    if (project.facade_sets.length > 0) {
      setFacadeSetid(project.facade_sets[0].id);
      setCurrentFacadeSet(project.facade_sets[0].geometry);
    } else {
      setCurrentFacadeSet(project.coordinates);
    }
    getObservations();
  }, [facadeSetId]);
  console.log(facadeSetId);
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [12.57, 55.67],
      zoom: 14,
      maxPitch: 0,
    });

    map.on("load", () => {
      map.addSource("facades", {
        type: "geojson",
        data: currentFacadeSet,
      });
    });
  }, []);

  const getObservations = async () => {
    const response = await axios.get(
      `/projects/${project.id}/facade_observations`
    );
    if (response.status === 200) {
      let facadeObservations = response.data.filter(
        (el) => el.facade_set_id === facadeSetId
      );
      let projectObservations = response.data
      console.log(projectObservations)

    
    }
  };

  // const getFacadeObservations = async () => {
  //   const response = await axios.get(
  //     `/facade_sets/${facadeSetId}/facade_observations`
  //   );
  //   if (response.status === 200) {
  //     let facadeObservations = response.data;
  //     console.log(facadeObservations);
  //   }
  // };

  // const getProjectObservations = async () => {
  //   const response = await axios.get(
  //     `/projects/${project.id}/facade_observations`
  //   );
  //   if (response.status === 200) {
  //     let projectObservations = response.data.filter(
  //       (el) => el.project_id == project.id
  //     );
  //     // console.log(projectObservations);
  //     projectObservations.map((obs)=>{
  //       let f = feature(obs.geometry, { observations: [obs]})
  //       f.properties.color = getColor(obs)
  //    setCurrentFacadeSet(featureCollection(f))
  //     })
  //   }
  // };

  const handleFacadeSetClick = (fs) => {
    setFacadeSetid(fs.id);
    setCurrentFacadeSet(fs.geometry);
  };

  const getColor = (observation) => {
    if (observation.observation_attributes_primary !== null) {
      return project.facade_observation_attribute_definitions.find(
        (f) => f.code === observation.observation_attributes_primary
      )?.color;
    }
  };

  return (
    <>
      <h1>View Data</h1>
      <div className="view-data-container">
        <div ref={mapContainer} className="data-map-container"></div>
        <div className="info-container">
          {project.facade_sets.length > 0 && (
            <ul className="facade_sets_list">
              <h2>Facade Sets</h2>
                <button   className="btn btn-primary">
                  All Facade Sets
                </button>
              {project.facade_sets.map((fs) => {
                if (!fs.archived_on) {
                  return (
                   <button
                      className="btn btn-primary"
                      key={fs.id}
                      value={fs}
                      onClick={() => handleFacadeSetClick(fs)}
                    >
                      {fs.name}
                    </button>
                  );
                }
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewData;
