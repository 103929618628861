import React, { useState, useEffect } from "react";
import axios from "../../lib/axios-instance";

const ShowProject = ({ slug }) => {
  const [project, setProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProject = async () => {
    const response = await axios.get(`/api/projects/${slug}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    setProject(response.data);
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <div id="show-project-container" className="list-container">

      <a href="/" className="small">← Back to all projects</a>
      
      <h2 className="mt-4 mb-2">{project.name}</h2>
      <p className="small">{project.city}</p>

      <br />

      {project.facade_sets?.length > 0 ? (
        <div>
          <p className="small">Select a geometry set</p>
          <ul className="list-unstyled">
            {project.facade_sets?.map((facade_set) => {
              return (
                <li key={facade_set.id} className="mb-3">
                  <a
                    href={`/projects/${slug}/facade_sets/${facade_set.slug}`}
                    className="d-flex justify-content-between"
                  >
                    <span>{facade_set.name}</span>
                    <span>→</span>
                  </a>
                </li>
              );
            })}
          </ul>

          <a className="fw-bold mt-4 d-flex justify-content-between" href={`/projects/${slug}/map`}>
            <span>Or go to whole city</span>
            <span>→</span>
          </a>
        </div>
      ) : (
        <a className="fw-bold d-flex justify-content-between" href={`/projects/${slug}/map`}>
          <span>Start mapping</span>
          <span>→</span>
        </a>
      )}
      
    </div>
  );
};

export default ShowProject;
