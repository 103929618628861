import React, { createContext, useState, useRef, useEffect } from "react";
import { feature, featureCollection } from "@turf/helpers";

import { getColor, getColorQty } from "../utils/utils";
import {
  resetUrl,
  addColorToFacades,
  formattedFloors,
} from "../../../utils/utils";

import {
  getObservations,
  getPoiObservations,
  getAttributes,
  getProjectBySlug,
  getFacadeSetByProjectSlugAndSlug,
  postFacadeObservationCollection,
  postPoiObservation,
} from "../../../utils/api";

import {
  faMinus,
  faLeftLong,
  faImage,
  faGlobe,
  faCheck,
  faPlus,
  faCircleInfo,
  faEye,
  faEyeSlash,
  faMap,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

export const FacadeContext = createContext();

const FacadeProvider = (props) => {

  const { children } = props;

  const [userId, setUserId] = useState(props.userId);

  const [projectSlug, setProjectSlug] = useState(props.projectSlug);
  const [facadeSetSlug, setFacadeSetSlug] = useState(props.facadeSetSlug);
  const [project, setProject] = useState(null);
  const [facadeSet, setFacadeSet] = useState(null);

  const observationAttributes = useRef({});

  const [selectedFacades, setSelectedFacades] = useState([]);
  const [drawnFeatures, setDrawnFeatures] = useState([]);
  const [currentPoiFeature, setCurrentPoiFeature] = useState({});

  const [showObservationForm, setShowObservationForm] = useState(false);
  const [showPoiForm, setShowPoiForm] = useState(false);
  const [visualizationMode, setVisualizationMode] = useState(false);
  const [showInfo, setShowInfo] = useState(false);


  const [floors, setFloors] = useState(null);
  const [attributePrimary, setAttributePrimary] = useState(null);
  const [attributeSecondary, setAttributeSecondary] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [currentComment, setCurrentComment] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);

  library.add(
    faMinus,
    faLeftLong,
    faImage,
    faGlobe,
    faCheck,
    faPlus,
    faCircleInfo,
    faEye,
    faEyeSlash,
    faMap
  );

  const [selectedFacadeSet, setSelectedFacadeSet] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [drawnFacades, setDrawnFacades] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [facadeSetPoints, setFacadeSetPoints] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const [drawnPois, setDrawnPois] = useState([]);

  const formatObservations = (data) => {
    let projectObservations = data;

    if (facadeSet && facadeSet.id) {
      let facadeObservations = projectObservations.filter(
        (el) => el.facade_set_id == facadeSet.id
      );

      let selectedFacadeSetFeatures = facadeSet.geometry.features.map((f) => {
        let matchingObservations = facadeObservations.filter(
          (o) => o.facade_feature_id === f.properties.facade_feature_id
        );
        f.properties.observations = matchingObservations;

        matchingObservations.length > 0
          ? (f.properties.color = getColor(
              matchingObservations.at(-1),
              observationAttributes
            ))
          : (f.properties.color = null);

        return f;
      });

      setSelectedFacadeSet(featureCollection(selectedFacadeSetFeatures));

      let drawnFacadeObservationFeatures =
        getDrawnFacadeObservationFeatures(facadeObservations);
      setDrawnFacades(featureCollection(drawnFacadeObservationFeatures));
    } else {
      let drawnFacadeObservationFeatures =
        getDrawnFacadeObservationFeatures(projectObservations);
      setDrawnFacades(featureCollection(drawnFacadeObservationFeatures));
    }
  };

  const getDrawnFacadeObservationFeatures = (observationFeatures) => {
    return observationFeatures
      .filter((o) => o.user_defined)
      .map((d) => {
        let f = feature(d.geometry, { observations: [d] });
        f.properties.color = getColor(d, observationAttributes);
        return f;
      });
  };

  const formatPoiObservations = (data) => {
    setDrawnPois(data.map((p) => p.geometry));
  };

  const formatAttributes = (data) => {
    let categorized = {};
    categorized.facadePrimary = data.facadeAttributes.filter(
      (f) => f.is_primary
    );
    categorized.facadeSecondary = data.facadeAttributes.filter(
      (f) => f.is_secondary
    );

    categorized.poiPrimary = data.poiAttributes.filter((f) => f.is_primary);
    categorized.poiSecondary = data.poiAttributes.filter((f) => f.is_secondary);

    categorized.facadePrimary = addColorToFacades(categorized.facadePrimary);

    observationAttributes.current = categorized;
  };

  const handleClickOpenObservationsForm = () => {
    if (selectedFacades.length > 0 || drawnFeatures.length > 0) {
      setShowObservationForm(true);
    }
  };

  const handleCloseActionsContainer = () => {
    setShowObservationForm(false);
    setFloors(null);
    setAttributePrimary(null);
    setAttributeSecondary([]);
    setSubmitFailed(false);
  };

  const handleClosePoiForm = () => {
    clearForm();
  };

  const handleImageChange = (file) => {
    setCurrentImage(file);
    setCurrentImageUrl(URL.createObjectURL(file));
  };

  const handleClickSecondaryAttribute = (code) => {
    setAttributeSecondary((attributeSecondary) =>
      attributeSecondary.includes(code)
        ? attributeSecondary.filter((a) => a !== code)
        : [...attributeSecondary, code]
    );
  };

  const appendDefault = (formData, userId) => {
    formData.append("facade_observations[][user_id]", userId);
    if(facadeSet) formData.append("facade_observations[][facade_set_id]", facadeSet.id);
    formData.append("facade_observations[][project_id]", project.id);
    if (attributePrimary)
      formData.append(
        "facade_observations[][observation_attributes_primary]",
        attributePrimary
      );
    if (attributeSecondary)
      formData.append(
        "facade_observations[][observation_attributes_secondary]",
        attributeSecondary
      );
    if (currentComment)
      formData.append("facade_observations[][notes]", currentComment);
    if (currentImage)
      formData.append("facade_observations[][image]", currentImage);
    if (floors) {
      console.log(floors);
      formData.append(
        "facade_observations[][number_of_floors]",
        formattedFloors(floors)
      );
    }
      
  };

  const submitFacadeObservation = () => {
    const formData = new FormData();

    if (selectedFacades.length > 0) {
      selectedFacades.forEach((facadeId) => {
        appendDefault(formData, userId);
        let geometry = selectedFacadeSet.features.find(
          (s) => s.properties.facade_feature_id === facadeId
        )?.geometry;
        formData.append(
          "facade_observations[][geometry]",
          JSON.stringify(geometry)
        );
        formData.append("facade_observations[][facade_feature_id]", facadeId);
        formData.append("facade_observations[][user_defined]", false);
      });
    }

    if (drawnFeatures.length > 0) {
      drawnFeatures.forEach((drawnFeature) => {
        appendDefault(formData, userId);
        let geometry = drawnFeature.geometry;
        formData.append(
          "facade_observations[][geometry]",
          JSON.stringify(geometry)
        );
        formData.append(
          "facade_observations[][facade_feature_id]",
          drawnFeature.properties.facade_feature_id
        );
        formData.append("facade_observations[][user_defined]", true);
      });
    }

    postFacadeObservationCollection(formData)
      .then((response) => {
        response?.data.forEach((el) => {
          if (!el.user_defined) {
            setSelectedFacadeSet((selectedFacadeSet) => {
              let updatedFeatures = selectedFacadeSet.features.map((f) => {
                if (f.properties.facade_feature_id === el.facade_feature_id) {
                  f.properties.observations.push(el);
                  f.properties.color = getColor(el, observationAttributes);
                }
                return f;
              });
              return featureCollection(updatedFeatures);
            });

            setFacadeSetPoints((facadeSetPoints) => {
              let updatedFeatures = facadeSetPoints.features.map((p) => {
                if (p.properties.facade_feature_id === el.facade_feature_id)
                  p.properties.observations.push(el);
                return p;
              });
              return featureCollection(updatedFeatures);
            });
          } else {
            let f = feature(el.geometry, { observations: [el] });
            f.properties.color = getColor(el, observationAttributes);

            setDrawnFacades((drawnFacades) => ({
              ...drawnFacades,
              features: [...drawnFacades.features, f],
            }));
          }
        });
        clearForm();
      })
      .catch((error) => {
        setSubmitFailed(true);
      });

  };

  const submitPoiForm = () => {
    const formData = new FormData();
    formData.append("poi_observation[user_id]", userId);
    formData.append("poi_observation[project_id]", project.id);
    if (attributePrimary)
      formData.append(
        "poi_observation[observation_attributes_primary]",
        attributePrimary
      );
    if (attributeSecondary)
      formData.append(
        "poi_observation[observation_attributes_secondary]",
        attributeSecondary
      );
    formData.append(
      "poi_observation[geometry]",
      JSON.stringify(currentPoiFeature.geometry)
    );
    if (currentComment)
      formData.append("poi_observation[notes]", currentComment);
    if (currentImage) formData.append("poi_observation[image]", currentImage);

    postPoiObservation(formData)
      .then((response) => {
        if (response.status === 200)
          setDrawnPois((drawnPois) => [...drawnPois, response.data.geometry]);
        clearForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearForm = () => {
    setAttributePrimary(null);
    setAttributeSecondary([]);
    setCurrentComment(null);
    setCurrentImage(null);
    setSelectedFacades([]);
    setDrawnFeatures([]);
    setShowPoiForm(false);
    setShowObservationForm(false);
    setFloors(null);
    setHasSubmitted(false);
  };

  useEffect(() => {
    if (!projectSlug) return;

    getProjectBySlug(projectSlug).then((res) => {
      setProject(res.data);
    });

    getFacadeSetByProjectSlugAndSlug(projectSlug, facadeSetSlug).then((res) => {
      setFacadeSet(res.data);
    });
  }, [projectSlug]);

  useEffect(() => {
    if (!project || (facadeSetSlug && !facadeSet)) return;

    Promise.all([
      getObservations(project.id),
      getPoiObservations(project.id),
      getAttributes(project.id),
    ]).then(([observationData, poiObservationData, attributeData]) => {
      formatAttributes(attributeData.data);
      formatObservations(observationData.data);
      formatPoiObservations(poiObservationData.data);
    });
  }, [project, facadeSet]);

  useEffect(() => {
    if (observationAttributes.current.facadePrimary !== undefined) {
      getColorQty(observationAttributes, selectedFacadeSet, drawnFacades);
    }
  }, [visualizationMode]);

  return (
    <FacadeContext.Provider
      value={{
        userId,

        project,
        setProject,
        facadeSet,
        setFacadeSet,

        observationAttributes,

        selectedFacades,
        setSelectedFacades,
        drawnFeatures,
        setDrawnFeatures,
        currentPoiFeature,
        setCurrentPoiFeature,

        formattedFloors,

        selectedFacadeSet,
        setSelectedFacadeSet,
        drawnFacades,
        setDrawnFacades,
        facadeSetPoints,
        setFacadeSetPoints,
        drawnPois,
        setDrawnPois,

        floors,
        setFloors,
        attributePrimary,
        setAttributePrimary,
        attributeSecondary,
        setAttributeSecondary,
        currentImage,
        setCurrentImage,
        currentImageUrl,
        setCurrentImageUrl,
        currentComment,
        setCurrentComment,
        submitFailed,
        setSubmitFailed,
        hasSubmitted, 
        setHasSubmitted,

        showObservationForm,
        setShowObservationForm,
        showPoiForm, 
        setShowPoiForm,
        visualizationMode, 
        setVisualizationMode,
        showInfo,
        setShowInfo,

        handleClickOpenObservationsForm,
        handleCloseActionsContainer,
        handleClosePoiForm,
        handleImageChange,
        handleClickSecondaryAttribute,

        submitFacadeObservation,
        submitPoiForm,
        clearForm
      }}
    >
      {children}
    </FacadeContext.Provider>
  );
};

export default FacadeProvider;
