import React, { useState, useEffect } from "react";

import {
  getFacadeObservationImages,
  getPoiObservationImages,
} from "../../utils/api";

const ProjectImages = (props) => {
  const [project, setProject] = useState(JSON.parse(props.project));
  const [hasLoaded, setHasLoaded] = useState({ facade: false, poi: false });

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!project) return;

    getFacadeObservationImages(project.id)
      .then((res) => {
        setImages((images) => [...images, ...res.data]);
        setHasLoaded((hasLoaded) => ({ ...hasLoaded, facade: true }));
      })
      .catch((error) => {
        console.log(error);
      });

    getPoiObservationImages(project.id)
      .then((res) => {
        setImages((images) => [...images, ...res.data]);
        setHasLoaded((hasLoaded) => ({ ...hasLoaded, poi: true }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [project]);

  return (
    <div className="row justify-content-center my-4">
      <div className="col-12 col-lg-10">
        <a
          href={`/admin/projects/${project?.id}`}
          className="text-secondary small"
        >
          ← Back
        </a>
      </div>

      <div className="mt-2 col-12 col-lg-10">
        <h1>Images for {project?.name}</h1>

        {!hasLoaded.facade && !hasLoaded.poi && (
          <p className="mt-4r">Loading images...</p>
        )}

        {hasLoaded.facade && hasLoaded.poi && images.length === 0 && (
          <p className="mt-4">No images found for this project.</p>
        )}

        {hasLoaded.facade && hasLoaded.poi && images.length > 0 && (
          <>
            <div id="project-images-gallery" className="mt-4 d-flex">
              {images.map((image) => (
                <div key={image.id} className="gallery-image">
                  <img src={image.thumbnail_url} alt={image.observation.id} />

                  <div>
                    <div className="d-flex justify-content-between">
                      <p className="extra-small">
                        {new Date(image.observation.created_at).toUTCString()}
                      </p>
                      <a
                        href={image.image_url}
                        target="_blank"
                        className="extra-small"
                      >
                        View full-size ↗
                      </a>
                    </div>

                    <p className="small mb-0">Observation ID:</p>
                    <p className="small bold">{image.observation.id}</p>

                    {image.observation.observation_attributes_primary && (
                      <>
                        <p className="small mb-0">Primary Attribute:</p>
                        <p className="small bold">
                          {image.observation.observation_attributes_primary}
                        </p>
                      </>
                    )}
                    {image.observation.observation_attributes_secondary && (
                      <>
                        <p className="small mb-0">Secondary Attribute(s):</p>
                        <p className="small bold">
                          {image.observation.observation_attributes_secondary}
                        </p>
                      </>
                    )}

                    {image.observation.notes && (
                      <>
                        <p className="small mb-0">Notes:</p>
                        <p className="small bold">{image.observation.notes}</p>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectImages;
