import React from "react";
import { createRoot } from "react-dom/client";

import App from "../components/App";
import ProjectAdmin from "../components/projectAdmin/ProjectAdmin";
import ProjectImages from "../components/projectImages/ProjectImages";
import CreateProject from "../components/createProject/CreateProject";
import UsersList from "../components/userList/UserList";

import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/scrollspy";
import "bootstrap/js/src/tab";


import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

const mainContainer = document.getElementById("main");
const showProjectContainer = document.getElementById("show-project-container");
const projectImagesContainer = document.getElementById("show-project-images-container");
const projectFormContainer = document.getElementById("project_form_container");
const usersListContainer = document.getElementById("users-list-container");

if (mainContainer) {
  let userId = mainContainer.dataset.userId;
  let mapboxAccessToken = mainContainer.dataset.mapboxAccessToken;
  let mapboxStyleSatellite = mainContainer.dataset.mapboxStyleSatellite;
  let mapboxStyleVector = mainContainer.dataset.mapboxStyleVector;

  const root = createRoot(mainContainer);
  root.render(
    <App
      userId={userId}
      mapboxAccessToken={mapboxAccessToken}
      mapboxStyleSatellite={mapboxStyleSatellite}
      mapboxStyleVector={mapboxStyleVector}
    />
  );
}

if (showProjectContainer) {
  let project = showProjectContainer.dataset.project;
  let users = showProjectContainer.dataset.users;
  const root = createRoot(showProjectContainer);
  root.render(<ProjectAdmin project={project} users={users} />);
}


if (projectImagesContainer) {
  let project = projectImagesContainer.dataset.project;
  const root = createRoot(projectImagesContainer);
  root.render(<ProjectImages project={project} />);
}

if (projectFormContainer) {
  let projects = projectFormContainer.dataset.projects;
  const root = createRoot(projectFormContainer);
  root.render(<CreateProject projects={projects} />);
}

if (usersListContainer) {
  let users = usersListContainer.dataset.users;
  const root = createRoot(usersListContainer);
  root.render(<UsersList users={users} />);
}
