import React, { useState, useEffect } from "react";
import { isStrictGeoJSON } from "@konfirm/geojson";
import { uuidv4 } from "../../utils/utils";
import axios from "../../lib/axios-instance";
import { slugify, isJson } from "./utils";

const FacadeSetForm = ({ projectId, handleCreatedFacadeSet }) => {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [geometry, setGeometry] = useState(null);

  const [isValidGeom, setIsValidGeom] = useState(true);
  const [isValidSlug, setIsValidSlug] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [currentNames, setCurrentNames] = useState([]);
  const [currentSlugs, setCurrentSlugs] = useState([]);
  const project_id = projectId.replace(/"|'/g, "");

  useEffect(() => {
    axios.get(`/admin/facade_sets/all`)
      .then((res) => {
        setCurrentNames(res.data.map(s => s.name));
        setCurrentSlugs(res.data.map(s => s.slug));
        
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const geometryHasId = (geometry) => {
    const data = JSON.parse(geometry);
    const features = data.features;
    features.forEach((f) => {
      if (!f.properties.hasOwnProperty("facade_feature_id")) {
        f.properties.facade_feature_id = uuidv4();
        setGeometry(JSON.stringify(data));
      }
    });
  };

  const handleGeometryChange = (e) => {
    //e.preventDefault();
    setGeometry(e.target.value);
    if (isJson(e.target.value) && isStrictGeoJSON(JSON.parse(e.target.value))) {
      geometryHasId(e.target.value);
      setIsValidGeom(true);
    } else {
      setIsValidGeom(false);
    }
  };

  const handleSlugChange = (e) => {
    setSlug(e.target.value);
    if (!currentSlugs.includes(e.target.value)) {
      setIsValidSlug(true);
    } else {
      setIsValidSlug(false);
    }
  };

  const handleNameChange = (e) => {
    let slugifiedName = slugify(e.target.value);
    setName(e.target.value);
    if (!currentNames.includes(e.target.value)) {
      setIsValidName(true);
      setSlug(slugifiedName);
      !currentSlugs.includes(slugifiedName)
        ? setIsValidSlug(true)
        : setIsValidName(false);
    } else {
      setIsValidName(false);
    }
  };

  const submitFacade = (e) => {
    //e.preventDefault();
    const formData = new FormData();
    formData.append("facade_sets[name]", name);
    formData.append("facade_sets[slug]", slug);
    formData.append("facade_sets[geometry]", geometry);
    formData.append("facade_sets[project_id]", project_id);

    axios
      .post(`/admin/projects/${project_id}/facade_sets`, formData)
      .then((res) => {
        handleCreatedFacadeSet(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let createBtnStyle =
    geometry && isValidGeom && isValidSlug && name && isValidName
      ? "btn btn-md btn-round btn-black"
      : "btn btn-md btn-round btn-black " + "disabled";

  return (
    <div className="my-3">
      <div className="row g-3" >
        <div className="col-md-8">
          <div className="form-group mb-2">
            <label className="form-label small">Name</label>
            <input
              className="form-control form-control-sm"
              name="name"
              value={name}
              onChange={(e) => handleNameChange(e)}
            />
            {!isValidName && <small>The selected name is already in use</small>}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group mb-2">
            <label className="form-label small">Slug</label>
            <input
              className="form-control form-control-sm"
              name="slug"
              value={slug}
              onChange={(e) => handleSlugChange(e)}
            />
            {!isValidSlug && <small>The selected slug is already in use</small>}
          </div>
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label small">Geometry</label>
        <textarea
          className="form-control form-control-sm"
          rows="6"
          placeholder="Enter a valid geojson..."
          value={geometry}
          name="geometry"
          onChange={(e) => handleGeometryChange(e)}
        />
        {!isValidGeom && <small>Geometry should be valid geoJSON</small>}
      </div>

      <button className={createBtnStyle} onClick={() => submitFacade()}>
        Create
      </button>
    </div>
  );
};

export default FacadeSetForm;
