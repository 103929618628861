import React, { useContext } from "react";
import { FacadeContext } from "./context/FacadeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActionsContainer = () => {
  const {
    project,
    handleClickOpenObservationsForm,
    clearForm,
    selectedFacades,
    drawnFeatures,
    visualizationMode,
  } = useContext(FacadeContext);

  let observationSelected =
    selectedFacades.length > 0 || drawnFeatures.length > 0;

  return (
    <div className="actions-container">
      <div className="action-btn action-btn-sm flex-start">
        <a href={`/projects/${project?.slug}`}>
        <div className="action-btn-content d-flex">
          <FontAwesomeIcon icon="fa-solid fa-left-long" />
        </div>
        </a>
      </div>
      {(!visualizationMode && observationSelected) && (
        <div
          className="add-observation-btn btn btn-xl btn-round btn-white"
          onClick={() => handleClickOpenObservationsForm()}
        >
          <div className="add-observation-btn-content d-flex fw-bold">Add Observation</div>
        </div>
      )}
      {(!visualizationMode && observationSelected) && (
        <div className="action-btn action-btn-sm" onClick={() => clearForm()}>
          <div className="action-btn-content d-flex">
            <FontAwesomeIcon icon="fa-solid fa-minus" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionsContainer;
