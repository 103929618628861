import React, { useEffect, useState } from "react";
import ProjectsList from "./projectsList/ProjectsList";
import ShowProject from "./showProject/ShowProject";
import ObservationInterface from "./observationInterface/ObservationInterface";

const App = (props) => {
  const { userId, mapboxAccessToken, mapboxStyleSatellite, mapboxStyleVector } = props;
  const [component, setComponent] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/") {
      setComponent(<ProjectsList />);
    
    } else if (path.startsWith("/projects/") && path.includes("/facade_sets/")) {
      const [projectSlug, facadeSetSlug] = path.split("/projects/")[1].split("/facade_sets/");
      setComponent(<ObservationInterface projectSlug={projectSlug} facadeSetSlug={facadeSetSlug} mapboxAccessToken={mapboxAccessToken} mapboxStyleSatellite={mapboxStyleSatellite} mapboxStyleVector={mapboxStyleVector} userId={userId} />);
    
    } else if (path.startsWith("/projects/") && path.includes("/map")) {
      const projectSlug = path.split("/")[2];
      setComponent(<ObservationInterface projectSlug={projectSlug} mapboxAccessToken={mapboxAccessToken} mapboxStyleSatellite={mapboxStyleSatellite} mapboxStyleVector={mapboxStyleVector} userId={userId} />);
    
    } else if (path.startsWith("/projects/")) {
      const slug = path.split("/projects/")[1];
      setComponent(<ShowProject slug={slug} />);
    }
  }, []);

  return (
    <>
      {component}
    </>
  );
};

export default App;