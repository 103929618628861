import axios from "../lib/axios-instance";

const getObservations = (projectId) => {
  return axios.get(`/projects/${projectId}/facade_observations`);
};

const getAttributes = async (projectId) => {
  return axios.get(`/projects/${projectId}/attributes`);
};

const getPoiObservations = async (projectId) => {
  return axios.get(`/projects/${projectId}/poi_observations`);
};

const getProjectBySlug = async (projectSlug) => {
  return axios.get(`/api/projects/${projectSlug}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

const getFacadeSetByProjectSlugAndSlug = async (projectSlug, facadeSetSlug) => {
  return axios.get(`/api/projects/${projectSlug}/facade_sets/${facadeSetSlug}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

const postFacadeObservationCollection = async (formData) => {
  return axios.post(`/facade_observation_collection`, formData);
}

const postPoiObservation = async (formData) => {
  return axios.post(`/poi_observations`, formData);
};

const getFacadeObservationImages = async (projectId) => {
  return axios.get(`/admin/projects/${projectId}/facade_observation_images`);
};

const getPoiObservationImages = async (projectId) => {
  return axios.get(`/admin/projects/${projectId}/poi_observation_images`);
};

export { getObservations, getAttributes, getPoiObservations, getProjectBySlug, getFacadeSetByProjectSlugAndSlug, postFacadeObservationCollection, postPoiObservation, getFacadeObservationImages, getPoiObservationImages };