import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FacadeContext } from "./context/FacadeProvider";

const ObservationForm = () => {
  const {
    userId,
    showObservationForm,
    handleCloseActionsContainer,
    currentImage,
    currentImageUrl,
    currentComment,
    setCurrentComment,
    submitFacadeObservation,
    floors,
    setFloors,
    formattedFloors,
    submitFailed,
    observationAttributes,
    handleImageChange,
    attributePrimary,
    setAttributePrimary,
    attributeSecondary,
    handleClickSecondaryAttribute,
    project,
    hasSubmitted,
    setHasSubmitted,
  } = useContext(FacadeContext);

  let floorsStr = formattedFloors(floors);
  if (!floorsStr) floorsStr = "Not set";

  const showFloorsSlider = project?.show_floors_slider;

  const canSubmit =
    attributePrimary || floors || currentImage || currentComment;

  const handleSubmitFacadeObservation = () => {
    if (hasSubmitted) return;

    submitFacadeObservation();

    setHasSubmitted(true);
  };

  const PrimaryButton = ({ attribute }) => {
    let btnClasses = "attribute-btn d-block btn btn-md p-2 mb-2 half";
    btnClasses +=
      attributePrimary === attribute.code ? " btn-black" : " btn-outline";

    return (
      <button
        className={btnClasses}
        onClick={() => attributePrimary === attribute.code ? setAttributePrimary(null) : setAttributePrimary(attribute.code)}
      >
        <p className="fw-bold mb-0">{attribute.code}</p>
        <p className="small my-0">{attribute.description}</p>
      </button>
    );
  };

  const SecondaryButton = ({ attribute }) => {
    let btnClasses = "attribute-btn d-block btn btn-md p-1 mb-2";
    btnClasses += attributeSecondary.includes(attribute.code)
      ? " btn-black"
      : " btn-outline";

    if (observationAttributes.current.facadeSecondary.length === 2)
      btnClasses += " half";

    return (
      <button
        className={btnClasses}
        onClick={() => handleClickSecondaryAttribute(attribute.code)}
      >
        <p className="mb-0 small">{attribute.code}</p>
        <p className="extra-small my-0">{attribute.description}</p>
      </button>
    );
  };

  if (!showObservationForm) return null;

  return (
    <>
      <div className="facade-attribute-wrapper">
        <div className="facade-attribute-container">
          <div className="facade-attribute-header d-flex justify-content-between align-items-center mb-1">
            <h4 className="facade-attribute-heading small">
              Facade Observation
            </h4>
          </div>

          <div className="facade-attribute-content d-flex justify-content-between h-100 flex-column">
            <div className="my-2">
              <div className="attribute-container d-flex flex-wrap justify-content-between">
                {observationAttributes.current.facadePrimary?.map(
                  (attribute) => (
                    <PrimaryButton attribute={attribute} key={attribute.code} />
                  )
                )}
              </div>

              <div className="d-flex mt-3">
                <div className="mb-2 form-group input-group-sm d-flex flex-grow-1 me-2">
                  <textarea
                    className="form-control"
                    placeholder="Add a note (optional)"
                    name="note"
                    onChange={(e) => setCurrentComment(e.target.value)}
                  ></textarea>
                </div>

                <div className="form-group mb-2 w-25">
                  <div
                    className="image--preview-container"
                    style={{
                      backgroundImage: currentImage
                        ? `url(${currentImageUrl})`
                        : "none",
                    }}
                  >
                    <label
                      className="d-flex"
                      id="image--label"
                      htmlFor="image--input"
                    >
                      {!currentImage && (
                        <FontAwesomeIcon icon="fa-solid fa-image" />
                      )}
                    </label>
                    <input
                      type="file"
                      id="image--input"
                      name="img"
                      accept="image/*"
                      onChange={(event) =>
                        handleImageChange(event.target.files[0])
                      }
                      className="form-control-file shadow-none"
                    />
                  </div>
                </div>
              </div>
            </div>

            {showFloorsSlider && (
              <div className="form-group mb-2">
                <label
                  htmlFor="floor-slider"
                  className="form-label floors-input"
                >
                  Floors: <span>{floorsStr}</span>
                </label>
                <input
                  type="range"
                  className="form-range"
                  min="0"
                  max="11"
                  step="1"
                  defaultValue="0"
                  id="floor-slider"
                  onChange={(e) => setFloors(parseInt(e.target.value))}
                ></input>
              </div>
            )}

            {observationAttributes.current.facadeSecondary.length > 0 && (
              <div>
                <label className="form-label floors-input">
                  Additional Attributes
                </label>
                <div className="attribute-container attribute-container--secondary d-flex flex-wrap justify-content-between mb-2">
                  {observationAttributes.current.facadeSecondary?.map(
                    (attribute) => (
                      <SecondaryButton
                        attribute={attribute}
                        key={attribute.code}
                      />
                    )
                  )}
                </div>
              </div>
            )}

            <div className="submit-facade-container mt-2 w-100 d-flex justify-content-between">
              <button
                className="btn btn-round btn-outline"
                onClick={handleCloseActionsContainer}
              >
                Cancel
              </button>

              <button
                className={
                  canSubmit && !hasSubmitted
                    ? "btn btn-black btn-round"
                    : "btn btn-black btn-round btn-deactivated"
                }
                onClick={() => canSubmit && handleSubmitFacadeObservation()}
              >
                {hasSubmitted ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {submitFailed && (
        <div className="failed-container">
          <div className="failed-content">
            <h3>Submission failed</h3>
            <p>
              Something has gone wrong with the submission. Perhaps you don't
              have internet connection?
            </p>
            <p className="mb-0">Please reload the page and try again</p>
          </div>
        </div>
      )}
    </>
  );
};
export default ObservationForm;
