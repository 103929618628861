import React, { useState, useEffect } from "react";
import axios from "../../lib/axios-instance";
import LoadingImage from "/images/loading.svg";

const ProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProjects = async () => {
    const response = await axios.get(`/api/projects`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    setProjects(response.data);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div id="projects-list-container" className="list-container">
      <p className="small mb-2">Hi! Select a project to start mapping</p>
        {projects?.map((project) => {
          return (
            <li key={project.id} className="list-unstyled">
              <a href={`/projects/${project.slug}`}>
                <h2 className="mt-4 mb-1">{project.name}</h2>
                <p className="small">{project.city}</p>
              </a>
            </li>
          );
        })}
      <div id="loading-container" className={isLoading ? "active" : ""}>
        <img id="loading-spinner" src={LoadingImage} />
      </div>
    </div>
  );
};

export default ProjectsList;
