import React, { useContext, useState } from "react";
import { FacadeContext } from "./context/FacadeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OfflineNotice = () => {
  const { hasDismissedOfflineMsg } = useContext(FacadeContext);

  const [showMsg, setShowMessage] = useState(!hasDismissedOfflineMsg.current);

  const OffLineIcon = () => {
    return (
      <div
        className="offline-notice-display"
        onClick={() => setShowMessage(true)}
      >
        <FontAwesomeIcon icon="fa-solid fa-globe" />
      </div>
    );
  };

  return (
    <>
      <OffLineIcon />
      {showMsg && (
        <div className="offline-container">
          <div className="offline-content">
            <FontAwesomeIcon icon="fa-solid fa-globe" />

            <p>Looks like you're offline.</p>
            <p>The app needs internet to work.</p>

            <div
              className="btn btn-outline-light"
              onClick={() => setShowMessage(false)}
            >
              Dismiss
            </div>
          </div>
        </div>
      )}
      ;
    </>
  );
};

export default OfflineNotice;
