import React from "react";
import FacadeProvider from "./context/FacadeProvider";
import MapContainer from "./MapContainer";

const ObservationInterface = (props) => {

  const { userId, mapboxAccessToken, mapboxStyleSatellite, mapboxStyleVector, projectSlug, facadeSetSlug } = props;
  
  return (
    <FacadeProvider userId={userId} projectSlug={projectSlug} facadeSetSlug={facadeSetSlug}>
      <MapContainer
        mapboxAccessToken={mapboxAccessToken}
        mapboxStyleSatellite={mapboxStyleSatellite}
        mapboxStyleVector={mapboxStyleVector}
      />
    </FacadeProvider>
  );
};

export default ObservationInterface;
