const getColor = (observation, observationAttributes) => {
  if (!observationAttributes.current) return;
  if (observation.observation_attributes_primary !== null) {
    return observationAttributes.current.facadePrimary.find(
      (f) => f.code === observation.observation_attributes_primary
    )?.color;
  }
};

const getColorQty = (observationAttributes, selectedFacadeSet, drawnFacades) => {
  if (!observationAttributes.current) return;

  if (observationAttributes.current.facadePrimary !== null) {
    observationAttributes?.current.facadePrimary.map((c) => {
      c["quantity"] = 0;
      selectedFacadeSet.features.map((s) => {
        if (s.properties.color == c.color) {
          c.quantity++;
        }
      });
      drawnFacades.features.map((d) => {
        if (d.properties.color == c.color) {
          c.quantity++;
        }
      });
    });
  }
};

export { getColor, getColorQty };