import React, { useContext } from "react";
import { FacadeContext } from "./context/FacadeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PoiForm() {
  const {
    userId,
    showPoiForm,
    handleClosePoiForm,
    attributePrimary,
    setAttributePrimary,
    attributeSecondary,
    setCurrentComment,
    currentImage,
    handleImageChange,
    currentImageUrl,
    handleClickSecondaryAttribute,
    observationAttributes,
    submitPoiForm,
    hasSubmitted,
    setHasSubmitted,
  } = useContext(FacadeContext);

  const PrimaryButton = ({ attribute }) => {
    let btnClasses = "attribute-btn d-block btn btn-sm p-2 mb-2 half";
    btnClasses +=
      attributePrimary === attribute.code ? " btn-black" : " btn-outline";

    return (
      <button
        className={btnClasses}
        onClick={() => attributePrimary === attribute.code ? setAttributePrimary(null) : setAttributePrimary(attribute.code)}
      >
        <p className="fw-bold mb-0">{attribute.code}</p>
        <p className="small my-0">{attribute.description}</p>
      </button>
    );
  };

  const SecondaryButton = ({ attribute }) => {
    let btnClasses = "attribute-btn d-block btn btn-sm p-1 mb-2";
    btnClasses += attributeSecondary.includes(attribute.code)
      ? " btn-black"
      : " btn-outline";
    if (observationAttributes.current.poiSecondary.length === 2)
      btnClasses += " half";

    return (
      <button
        key={attribute.code}
        className={btnClasses}
        onClick={() => handleClickSecondaryAttribute(attribute.code)}
      >
        <p className="mb-0 small">{attribute.code}</p>
        <p className="extra-small my-0">{attribute.description}</p>
      </button>
    );
  };

  const handleSubmitPoiForm = () => {
    if (hasSubmitted) return;

    submitPoiForm();

    setHasSubmitted(true);
  };

  return (
    <>
      {showPoiForm && (
        <div className="facade-attribute-wrapper">
          <div className="facade-attribute-container">
            <div className="facade-attribute-header d-flex justify-content-between align-items-center mb-1">
              <h4 className="facade-attribute-heading small">
                Point of interest Observation
              </h4>
            </div>
            <div className="facade-attribute-content d-flex justify-content-between h-100 flex-column">
              <div className="my-2">
                <div className="attribute-container d-flex flex-wrap justify-content-between">
                  {observationAttributes.current.poiPrimary?.map(
                    (attribute) => (
                      <PrimaryButton
                        attribute={attribute}
                        key={attribute.code}
                      />
                    )
                  )}
                </div>

                <div className="d-flex">
                  <div className="mb-2 form-group input-group-sm d-flex flex-grow-1 me-2">
                    <textarea
                      className="form-control"
                      placeholder="Add a note (optional)"
                      name="note"
                      onChange={(e) => setCurrentComment(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group mb-2 w-25">
                    <div
                      className="image--preview-container"
                      style={{
                        backgroundImage: currentImage
                          ? `url(${currentImageUrl})`
                          : "none",
                      }}
                    >
                      <label
                        className="d-flex"
                        id="image--label"
                        htmlFor="image--input"
                      >
                        {!currentImage && (
                          <FontAwesomeIcon icon="fa-solid fa-image" />
                        )}
                      </label>
                      <input
                        type="file"
                        id="image--input"
                        name="img"
                        accept="image/*"
                        onChange={(event) =>
                          handleImageChange(event.target.files[0])
                        }
                        className="form-control-file shadow-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {observationAttributes.current.poiSecondary.length > 0 && (
              <div>
                <label className="form-label floors-input">
                  Additional Attributes
                </label>
                <div className="attribute-container attribute-container--secondary d-flex flex-wrap justify-content-between mb-2">
                  {observationAttributes.current.poiSecondary?.map(
                    (attribute) => (
                      <SecondaryButton
                        attribute={attribute}
                        key={attribute.code}
                      />
                    )
                  )}
                </div>
              </div>
            )}

            <div className="submit-facade-container mt-2 w-100 d-flex justify-content-between">
              <button
                className="btn btn-round btn-outline"
                onClick={() => handleClosePoiForm()}
              >
                Cancel
              </button>

              <button
                className={
                  hasSubmitted
                    ? "btn btn-round btn-black disabled"
                    : "btn btn-round btn-black"
                }
                onClick={() => handleSubmitPoiForm()}
              >
                {hasSubmitted ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PoiForm;
