import React, { useRef, useEffect, useState, useContext } from "react";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import bbox from "@turf/bbox";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { point, feature, featureCollection } from "@turf/helpers";
import { uuidv4 } from "../../utils/utils";
import { FacadeContext } from "./context/FacadeProvider";
import ActionsContainer from "./ActionsContainer";
import ObservationForm from "./ObservationForm";
import OfflineNotice from "./OfflineNotice";
import PoiForm from "./PoiForm";
import Info from "./Info";

const MapContainer = ({
  mapboxAccessToken,
  mapboxStyleSatellite,
  mapboxStyleVector,
}) => {
  mapboxgl.accessToken = mapboxAccessToken;

  const {
    project,
    facadeSet,

    selectedFacades,
    setSelectedFacades,
    setDrawnFeatures,
    isOffline,
    setCurrentPoiFeature,
    setShowPoiForm,
    visualizationMode,
    drawnFeatures,
    isStatelliteMap,

    selectedFacadeSet,
    drawnFacades,
    facadeSetPoints,
    setFacadeSetPoints,
    drawnPois,
  } = useContext(FacadeContext);

  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);


  const draw = useRef();

  const baseWidth = 4;
  const baseZoom = 18;

  const selectedFacadeColor = "#F7C649";
  const completedFacadeColor = "#4F92C2";
  const uncompletedFacadeColor = "#EB8154";
  const customFacadeColor = "#A7C8E1";

  const facadeDrawnPoints = useRef({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapboxStyleSatellite,
      center: [0, 0],
      zoom: baseZoom,
      maxPitch: 0,
    });

    var Draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        line_string: true,
        point: true,
        trash: true,
      },
      styles: [
        {
          id: "gl-draw-line",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": selectedFacadeColor,
            "line-width": {
              type: "exponential",
              base: 2,
              stops: [
                [0, baseWidth * Math.pow(1, 0 - baseZoom)],
                [24, baseWidth * Math.pow(2, 24 - baseZoom)],
              ],
            },
          },
        },
        {
          id: "gl-draw-polygon-and-line-vertex-active",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": {
              type: "exponential",
              base: 2,
              stops: [
                [0, baseWidth * Math.pow(1, 0 - baseZoom)],
                [24, baseWidth * Math.pow(2, 24 - baseZoom)],
              ],
            },
            "circle-color": selectedFacadeColor,
          },
        },
        {
          id: "gl-draw-line-static",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["==", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": uncompletedFacadeColor,
            "line-width": {
              type: "exponential",
              base: 2,
              stops: [
                [0, baseWidth * Math.pow(1, 0 - baseZoom)],
                [24, baseWidth * Math.pow(2, 24 - baseZoom)],
              ],
            },
          },
        },
        {
          id: "gl-draw-point",
          type: "circle",
          filter: ["all", ["==", "$type", "Point"]],
          paint: {
            "circle-color": completedFacadeColor,
            "circle-radius": {
              type: "exponential",
              base: 2,
              stops: [
                [0, baseWidth * Math.pow(1, 0 - baseZoom)],
                [24, baseWidth * Math.pow(2, 24 - baseZoom)],
              ],
            },
          },
        },
      ],
    });

    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      })
    );

    {
      !visualizationMode && map.addControl(Draw, "top-left");
    }

    draw.current = Draw;

    map.on("load", () => {
      map.addSource("facades", {
        type: "geojson",
        data: selectedFacadeSet,
      });

      map.addSource("facades-line-points", {
        type: "geojson",
        data: facadeSetPoints,
      });

      map.addSource("drawn-facades", {
        type: "geojson",
        data: drawnFacades,
      });

      map.addSource("drawn-facades-points", {
        type: "geojson",
        data: facadeDrawnPoints.current,
      });

      map.addSource("drawn-pois", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      map.addLayer({
        id: "facades",
        type: "line",
        source: "facades",
        paint: {
          "line-color": [
            "case",
            ["in", ["get", "facade_feature_id"], ["literal", selectedFacades]],
            selectedFacadeColor,
            ["!=", ["length", ["get", "observations"]], 0],
            completedFacadeColor,
            uncompletedFacadeColor,
          ],
          "line-width": {
            type: "exponential",
            base: 2,
            stops: [
              [0, baseWidth * Math.pow(1, 0 - baseZoom)],
              [24, baseWidth * Math.pow(2, 24 - baseZoom)],
            ],
          },
        },
        layout: {
          "line-cap": "round",
        },
      });

      map.addLayer({
        id: "facades-line-points",
        type: "circle",
        source: "facades-line-points",
        paint: {
          "circle-color": [
            "case",
            ["in", ["get", "facade_feature_id"], ["literal", selectedFacades]],
            selectedFacadeColor,
            ["!=", ["length", ["get", "observations"]], 0],
            completedFacadeColor,
            uncompletedFacadeColor,
          ],
          "circle-radius": {
            type: "exponential",
            base: 2,
            stops: [
              [0, baseWidth * Math.pow(1, 0 - baseZoom)],
              [24, baseWidth * Math.pow(2, 24 - baseZoom)],
            ],
          },
        },
      });

      map.addLayer({
        id: "drawn-facades",
        type: "line",
        source: "drawn-facades",
        paint: {
          "line-color": customFacadeColor,
          "line-width": {
            type: "exponential",
            base: 2,
            stops: [
              [0, baseWidth * Math.pow(1, 0 - baseZoom)],
              [24, baseWidth * Math.pow(2, 24 - baseZoom)],
            ],
          },
        },
        layout: {
          "line-cap": "round",
        },
      });

      map.addLayer({
        id: "drawn-facades-points",
        type: "circle",
        source: "drawn-facades-points",
        paint: {
          "circle-color": customFacadeColor,
          "circle-radius": {
            type: "exponential",
            base: 2,
            stops: [
              [0, baseWidth * Math.pow(1, 0 - baseZoom)],
              [24, baseWidth * Math.pow(2, 24 - baseZoom)],
            ],
          },
        },
      });

      map.addLayer({
        id: "drawn-pois",
        type: "circle",
        source: "drawn-pois",
        paint: {
          "circle-color": customFacadeColor,
          "circle-radius": {
            type: "exponential",
            base: 2,
            stops: [
              [0, baseWidth * Math.pow(1, 0 - baseZoom)],
              [24, baseWidth * Math.pow(2, 24 - baseZoom)],
            ],
          },
        },
      });

      map.on("click", (e) => {
        let buffer = 8;
        const bbox = [
          [e.point.x - buffer, e.point.y - buffer],
          [e.point.x + buffer, e.point.y + buffer],
        ];

        const selectedFeatures = map.queryRenderedFeatures(bbox, {
          layers: ["facades"],
        });
        if (selectedFeatures.length === 0) return;

        let clickedId = selectedFeatures[0].properties.facade_feature_id;
        setSelectedFacades((selectedFacades) =>
          selectedFacades.includes(clickedId)
            ? selectedFacades.filter((i) => i !== clickedId)
            : [...selectedFacades, clickedId]
        );
      });

      map.on("touchend", (e) => {
        if(map.isMoving() || map.isZooming() || map.isRotating()) return;

        let buffer = 8;
        const bbox = [
          [e.point.x - buffer, e.point.y - buffer],
          [e.point.x + buffer, e.point.y + buffer],
        ];

        const selectedFeatures = map.queryRenderedFeatures(bbox, {
          layers: ["facades"],
        });
        if (selectedFeatures.length === 0) return;

        let clickedId = selectedFeatures[0].properties.facade_feature_id;
        setSelectedFacades((selectedFacades) =>
          selectedFacades.includes(clickedId)
            ? selectedFacades.filter((i) => i !== clickedId)
            : [...selectedFacades, clickedId]
        );
      });

      setMap(map);
    });

    map.on("draw.create", function (e) {
      setSelectedFacades([]);
      if (e.features[0].geometry.type !== "Point") {
        let newFeature = e.features[0];
        newFeature.properties.facade_feature_id = uuidv4();
        setDrawnFeatures((drawnFeatures) => [...drawnFeatures, newFeature]);
      } else {
        setCurrentPoiFeature(e.features[0]);
        setShowPoiForm(true);
      }
    });

    return () => map.remove();
  }, []);

  useEffect(() => {
    if (!map) return;
    map.on("draw.delete", handleDrawDelete);
  }, [map, drawnFeatures]);

  useEffect(() => {
    if (!map) return;

    if (!visualizationMode) {
      map.setPaintProperty("facades", "line-color", [
        "case",
        ["in", ["get", "facade_feature_id"], ["literal", selectedFacades]],
        selectedFacadeColor,
        ["!=", ["length", ["get", "observations"]], 0],
        completedFacadeColor,
        uncompletedFacadeColor,
      ]);
      map.setPaintProperty("facades-line-points", "circle-color", [
        "case",
        ["in", ["get", "facade_feature_id"], ["literal", selectedFacades]],
        selectedFacadeColor,
        ["!=", ["length", ["get", "observations"]], 0],
        completedFacadeColor,
        uncompletedFacadeColor,
      ]);
      map.setPaintProperty("drawn-facades", "line-color", customFacadeColor);
      map.setLayoutProperty("facades-line-points", "visibility", "visible");
      map.setLayoutProperty("drawn-facades-points", "visibility", "visible");
    } else {
      map.setPaintProperty("facades", "line-color", ["get", "color"]);
      map.setPaintProperty("drawn-facades", "line-color", ["get", "color"]);
      map.setLayoutProperty("facades-line-points", "visibility", "none");
      map.setLayoutProperty("drawn-facades-points", "visibility", "none");
    }
  }, [selectedFacades, visualizationMode]);

  useEffect(() => {
    if (!map || !selectedFacadeSet.features.length > 0) return;
    map.getSource("facades").setData(selectedFacadeSet);
  }, [selectedFacadeSet]);

  useEffect(() => {
    if (!map || !facadeSetPoints.features.length > 0) return;
    map.getSource("facades-line-points").setData(facadeSetPoints);
  }, [facadeSetPoints]);


  // Decide the view for the map
  useEffect(() => {
    if (!map || !project || hasLoaded) return;

    // If there is a facade set, go to that
    if (facadeSet) {
      if (selectedFacadeSet.features.length > 0) { // wait for this to load
        // populate dataset for drawing circles at both ends of each line
        let pointFeatures = [];
        selectedFacadeSet.features.forEach((facade) => {
          let coords = facade.geometry.coordinates;

          if (coords.length == 0) return;
          let points = [point(coords[0]), point(coords[coords.length - 1])];
          points.forEach((p) => {
            p.properties.observations = facade.properties.observations;
            p.properties.facade_feature_id =
              facade.properties.facade_feature_id;
            pointFeatures.push(p);
          });
        });
        setFacadeSetPoints(featureCollection(pointFeatures));

        let drawnFeatures = drawnFacades.features.map((f) =>
          feature(f.geometry)
        );
        let bboxFeatureCollection = featureCollection([
          ...selectedFacadeSet.features,
          ...drawnFeatures,
        ]);

        map.getSource("facades").setData(selectedFacadeSet);

        map.fitBounds(bbox(bboxFeatureCollection), {
          duration: 10,
          padding: 40,
        });

        setHasLoaded(true);
      }

    // If there's no facade set, but there are drawn facades, go to that
    } else if (drawnFacades.features.length > 0) {
      map.fitBounds(bbox(drawnFacades), {
        duration: 10,
        padding: 40,
      });
      setHasLoaded(true);
    
    // If there's no facade set and no drawn facades, go to the project coordinates
    } else {
      map.jumpTo({
        center: project.coordinates.features[0].geometry.coordinates,
        zoom: 13,
      });
      setHasLoaded(true);
    }
  }, [map, project, facadeSet, selectedFacadeSet, drawnFacades]);

  useEffect(() => {
    if (!map) return;
    let pointFeatures = [];
    drawnFacades.features.forEach((drawn) => {
      let coords = drawn.geometry.coordinates;
      if (coords.length == 0) return;

      let points = [point(coords[0]), point(coords[coords.length - 1])];
      points.forEach((p) => {
        p.properties.facade_feature_id = drawn.properties.facade_feature_id;
        pointFeatures.push(p);
      });
    });
    facadeDrawnPoints.current.features = pointFeatures;

    map.getSource("drawn-facades").setData(drawnFacades);
    map.getSource("drawn-facades-points").setData(facadeDrawnPoints.current);
  }, [drawnFacades, map]);

  useEffect(() => {
    if (!map) return;
    let features = drawnPois.map((f) => feature(f));
    let fc = featureCollection(features);

    map.getSource("drawn-pois").setData(fc);
  }, [drawnPois, map]);

  useEffect(() => {
    if (!map) return;

    isStatelliteMap
      ? map.setStyle(mapboxStyleSatellite)
      : map.setStyle(mapboxStyleVector);
  }, [isStatelliteMap]);

  const handleDrawDelete = (e) => {
    let deletedFeatures = e.features[0].id;
    const updatedFeatures = drawnFeatures.filter((feature) => {
      return feature.id !== deletedFeatures;
    });

    setDrawnFeatures(updatedFeatures);
  };

  return (
    <div id="observation-interface-container">
      <div ref={mapContainer} className="map-container" />
      <ActionsContainer />
      <ObservationForm />
      <PoiForm />
      <Info />
      {isOffline && <OfflineNotice />}
    </div>
  );
};

export default MapContainer;
